<template>
  <ion-list>
    <ion-card class="ion-margin-bottom" color="light">
      <ion-item lines="none">
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </ion-label>
      </ion-item>
    </ion-card>
    <ion-card class="ion-margin-bottom" color="light">
      <ion-item lines="none">
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </ion-label>
      </ion-item>
    </ion-card>
    <ion-card class="ion-margin-bottom" color="light">
      <ion-item lines="none">
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </ion-label>
      </ion-item>
    </ion-card>
  </ion-list>
</template>

<script>
export default {
  name: "SkeletonTextCards"
};
</script>
