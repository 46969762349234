<template>
  <PrimePage :title="programName + ' - Add Patients'" titleAlignment="left" :loading="pageIsLoading" :backToNamedRoute="'ProgramDashboard'" :backText="'Back to ' + programName + ' Program Overview'">
    <ErrorNotification :errors="errors" />
    <ion-card color="light" class="padding-death split-screen-card">
      <ion-row>
        <ion-col>
          <div class="column-stretch-wrap full-height">
            <div v-if="patientsLoading" class="margin-top-twenty">
              <SkeletonTextCards />
            </div>
            <div v-else class="full-height">
              <div class="pad-top-twenty pad-lr-twenty">
                <h6 class="margin-top-zero">Find Patient to Add</h6>
                <div class="margin-bottom-twenty margin-top-ten">
                  <form @submit.prevent novalidate="true">
                    <ion-row>
                      <ion-col>
                        <input type="text" class="form-control" placeholder="Search for patient by name or ID" v-model="searchString" />
                      </ion-col>
                      <ion-col size="auto" class="hide margin-left-five">
                        <button @click="searchAllAvailablePatients" :disabled="!searchString" class="prime-button button-block button-primary">Search</button>
                      </ion-col>
                    </ion-row>
                    <ion-row class="margin-top-ten" v-if="availablePatients.length > 1">
                      <ion-col></ion-col>
                      <ion-col size="6">
                        <button @click="stageAllPatients()" class="prime-button button-block button-secondary">&plus; Add All</button>
                      </ion-col>
                      <ion-col></ion-col>
                    </ion-row>
                  </form>
                </div>
              </div>
              <div v-if="availablePatients.length == 0" class="centerItems text-muted display-flex valign full-width">
                <span v-if="searching">
                  <ion-spinner name="lines" class="spinner-medium" color="primary"></ion-spinner>
                </span>
                <h6 v-else class="text-muted text-bold">No available patients.</h6>
              </div>
              <div v-if="availablePatients.length > 0" class="pad-lr-twenty add-patient-card-scroll">
                <ion-card v-for="(patient, key) in availablePatients" :key="key" class="prime-patient-card ion-margin-bottom">
                  <div class="avatar" v-if="patient.beneFirstName && patient.beneLastName">
                    {{ patient.beneFirstName.charAt(0) + patient.beneLastName.charAt(0) }}
                  </div>
                  <div>
                    <div class="prime-patient-card-name">
                      {{ patient.beneLastName + ", " + patient.beneFirstName }}
                    </div>
                    <div class="prime-patient-card-info">
                      <strong>ID: </strong><span class="mrn-holder">{{ patient.mrn }}</span
                      ><strong>DOB:</strong>{{ patient.dob | moment("MM/DD/YYYY") }}
                    </div>
                  </div>
                  <div class="prime-patient-card-icons">
                    <button @click="stagePatient(patient, true)" class="prime-button button-block button-secondary">&plus; Add</button>
                  </div>
                </ion-card>
              </div>
            </div>
            <div v-if="availablePatients.length > 0" class="column-stretch-footer text-muted text-center pad-bottom-ten">
              <small>Showing 20 preview patients. Search will query all patients.</small>
            </div>
          </div>
        </ion-col>
        <ion-col class="pad-twenty bg-white border-left">
          <div class="column-stretch-wrap full-height">
            <div class="column-stretch-header pad-five">
              <h6 class="margin-top-zero">Patients to Add</h6>
            </div>
            <div class="column-stretch-body">
              <div v-if="stagedPatients.length > 0" class="margin-top-five add-patient-card-scroll pad-five">
                <ion-card v-for="(patient, key) in stagedPatients" :key="key" class="prime-patient-card ion-margin-bottom">
                  <div class="avatar" v-if="patient.beneFirstName && patient.beneLastName">
                    {{ patient.beneFirstName.charAt(0) + patient.beneLastName.charAt(0) }}
                  </div>
                  <div>
                    <div class="prime-patient-card-name">
                      {{ patient.beneLastName + ", " + patient.beneFirstName }}
                    </div>
                    <div class="prime-patient-card-info wider-info">
                      <strong>ID: </strong><span class="mrn-holder">{{ patient.mrn }}</span>
                      <div class="display-inline-block"><strong>PCP: </strong>{{ patient.pcpTitle | formatDisplayName(patient.pcpFirstName, patient.pcpLastName, patient.suffix) }}</div>
                    </div>
                  </div>
                  <div class="prime-patient-card-icons">
                    <span class="cursor-pointer text-muted close-button" @click="unstagePatient(patient, true)">&times;</span>
                  </div>
                </ion-card>
              </div>
              <!-- <div v-else class="centerItems text-muted display-flex valign full-height full-width"> -->
              <div v-else class="empty-container full-height text-muted">
                <IconPersonAdd class="prime-tasks-empty-icon icon-watermark icon-grey" />
                <h6>To get started, select patients to add.</h6>
              </div>
            </div>
            <div class="column-stretch-footer">
              <button @click="enrollPatients" :disabled="stagedPatients.length == 0" class="prime-button button-primary button-block margin-top-thirty">Add {{ stagedPatients.length > 0 ? stagedPatients.length : "" }} Patients</button>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-card>
  </PrimePage>
</template>

<script>
import PrimePage from "@/components/Global/PrimePage";
import ErrorNotification from "@/components/Global/ErrorNotification";
import GetUsersByProgram from "@/services/Api/getUsersByProgram";
import { send as httpSend } from "@/services/Api";
import { debounce } from "lodash";
import IconPersonAdd from "@/components/Global/Icons/IconPersonAdd";
import SkeletonTextCards from "@/components/Global/SkeletonTextCards";

export default {
  name: "ProgramAddPatients",
  components: {
    PrimePage,
    ErrorNotification,
    IconPersonAdd,
    SkeletonTextCards
  },
  data() {
    return {
      searching: false,
      pageIsLoading: true,
      patientsLoading: true,
      allPatients: [],
      stagedPatients: [],
      errors: [],
      searchString: undefined,
      programName: undefined
    };
  },
  watch: {
    searchString: function() {
      if (this.searchString.length < 1) {
        this.getAllAvailablePatients();
      }
    }
  },
  computed: {
    availablePatients() {
      const t = this;
      return this.allPatients.filter(({ beneficiaryId }) => t.stagedPatients && !t.stagedPatients.some(x => x.beneficiaryId == beneficiaryId));
    }
  },
  mounted() {
    this.getProgramName(this.$route.params.program_id);
  },
  created() {
    this.getAllAvailablePatients();
  },
  deactivated() {
    this.errors = [];
  },
  methods: {
    getProgramName(programId) {
      const method = "get";
      const path = document.config.programsApi + programId;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.programName = result.data.name;
          this.pageIsLoading = false;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "ERROR: Could not get program name.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    searchAllAvailablePatients: debounce(
      async function() {
        this.searching = true;
        try {
          this.allPatients = await GetUsersByProgram(this.$route.params.program_id, this.searchString);
        } finally {
          this.searching = false;
        }
      },
      1000,
      { leading: true, trailing: false }
    ),
    async getAllAvailablePatients() {
      this.patientsLoading = true;
      try {
        this.allPatients = await GetUsersByProgram(this.$route.params.program_id);
      } finally {
        this.patientsLoading = false;
      }
    },
    stagePatient: function(patient) {
      this.stagedPatients.push(patient);
    },
    stageAllPatients: function() {
      this.stagedPatients = [...this.allPatients];
      this.getAllAvailablePatients();
    },
    unstagePatient: function(patient) {
      const index = this.stagedPatients.map(x => x.beneficiaryId).indexOf(patient.beneficiaryId);
      this.stagedPatients.splice(index, 1);
    },
    async enrollPatients() {
      // this.isEnrolling = true;
      const path = document.config.programsApi + this.$route.params.program_id + "/patients/enroll";
      const method = "POST";
      let payload = new FormData();

      this.stagedPatients.forEach(el => payload.append("patients", el.accountId));

      try {
        await httpSend({ path, method, authToken: true, payload });
        this.isLoading = false;

        this.$ionic.toastController
          .create({
            header: "Success",
            message: this.stagedPatients.length == 1 ? "1 patient was added to the program" : this.stagedPatients.length + " patients were added to the program",
            duration: 4000,
            color: "primary",
            mode: "ios"
          })
          .then(m => m.present());

        this.stagedPatients = [];
        this.getAllAvailablePatients();
      } catch (err) {
        this.isLoading = false;
        this.errors.push(err.response.data.message);
      }
    }
  }
};
</script>

<style scoped>
form h5 {
  margin-top: 40px;
}

.city {
  display: inline-block;
  width: 73%;
}

.state {
  display: inline-block;
  width: 25%;
  margin-left: 2%;
}

.send-email {
  font-size: 11px;
  font-weight: 700;
  margin-top: 3px;
  color: var(--ion-color-primary);
}

.send-email.disabled {
  color: var(--ion-color-medium);
}
/* avatar styles */
ion-card.prime-patient-card .avatar {
  width: 40px;
  height: 40px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}

ion-card.prime-patient-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  transition: all 0.2s ease-in-out;
}

ion-card.prime-patient-card .prime-patient-card-name {
  font-size: 16px;
  font-weight: 500;
}

ion-card.prime-patient-card .prime-patient-card-info {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
}

ion-card.prime-patient-card .prime-patient-card-name,
ion-card.prime-patient-card .prime-patient-card-info {
  width: 100%;
  max-width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-patient-card .prime-patient-card-name.wider-info,
ion-card.prime-patient-card .prime-patient-card-info.wider-info {
  max-width: 360px;
}

ion-card.prime-patient-card .prime-patient-card-icons {
  display: flex;
  position: absolute;
  right: 20px;
}
.close-button {
  font-size: 30px;
  border-radius: 20px;
  color: #a0a0a0;
  padding: 1px;
}
.add-patient-card-scroll {
  height: 600px;
  overflow-y: scroll;
}

.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto;
}

.column-stretch-footer {
  flex: 0 0 auto;
}
.border-left {
  border-left: 2px solid #dadada;
}
.icon-watermark {
  width: 140px;
  fill: #dadada;
}
.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}

ion-card.split-screen-card {
  width: calc(100% - 60px);
  min-width: 800px;
  max-width: 1100px;
  align-self: center;
  flex-grow: 2;
}

.split-screen-card > ion-row,
.split-screen-card > ion-row > ion-col {
  height: 100%;
}

.mrn-holder {
  display: inline-block;
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: bottom;
}
.mrn-holder::after {
  content: " ";
  margin-right: 5px;
}
</style>
