import { send } from "@/services/Api";
import store from "@/store";

export default async (id, query) => {
  const method = "get";
  let path;
  query ? (path = document.config.programsApi + id + "/patients/eligible?search=" + query) : (path = document.config.programsApi + id + "/patients/eligible");
  try {
    const results = await send({ path, method, authToken: true });
    return results.data;
  } catch (error) {
    const errorDetails = {
      header: "There was an issue with retrieving users associated with this program",
      message: error
    };
    store.commit("errorHandler/toastableError", errorDetails);
  }
};
